<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="filtredGroups"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-group"
          v-if="is_admin"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>

    <modal
      id="add-group"
      title="Ajouter un group"
      size="medium"
      :footer="false"  
    >
      <form
        action=""
        class="horizontal-form"
        @submit.prevent="addingGroup"
      >   
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="name">Nom</label>
          <input
            class="form-control"
            type="text"
            name="name"
            id="name"
            v-model="name"
          >
        </div>
        <div class="form-group">
          <label for="description">description</label>
          <textarea
            rows="3"
            class="form-control"
            name="description"
            id="description"
            v-model="description"
          />
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              class="btn btn-sm btn-primary"
              type="submit"
            >
              Ajouter
            </button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import { COMPONENT_TYPE, DATE_TYPE, TEXT_TYPE } from '../../components/dataTable/dataType'
import DataTable from '../../components/dataTable/local.vue'
import Modal from '../../components/modal.vue'
// import {libelleComponentSort} from '../../components/dataTable/functions'
import Navbar from '../../components/navbar.vue'
import { ADD_GROUPE } from '../../graphql/group'
// import {BOOLEAN_TYPE, COMPONENT_TYPE, DATE_TYPE, TEXT_TYPE} from '../../components/dataTable/dataType'
const GroupEffectif = () => import('../../components/user/group/groupEffectif.vue')
const GroupCreateBy = () => import('../../components/user/group/createBy.vue')
const Actions = () => import('../../components/user/group/actions.vue')
export default {
    components: { DataTable, Navbar, Modal },
    data() {
      return {
        
        navbarItems: [
          {
              libelle: 'Groupe d\'utilisateurs'
          }
        ],
        pageIcon: 'la-users',
        pageTitle: 'Groupes utilisateurs',
        pageDescription: 'Liste des groupes d\'utilisateurs',
        name: null,
        description: null,
        has_error: false,
        error_msg: null
      }
    },
    methods: {
      ...mapMutations({
        done: 'DONE'
      }),
      addingGroup(){
          this.$apollo.mutate({
              mutation: ADD_GROUPE,
              variables: {
                "groupe": {
                  name: this.name,
                  description: this.description
                }
              },
              update: (store, { data  }) => {
                  this.done()
                  console.log(data)
              },
          }).then(({data}) => {
              console.log(data)
              this.$router.push({name: 'detail-group', params: {uid: data.addGroupe}})
          }).catch((error) => {
              console.error(error)
              this.has_error = true
              this.error_msg = error
          })
      }
    },
    computed: {
        ...mapGetters({
            groups: 'group/groups',
            is_admin: 'auth/is_admin'
        }),
        filtredGroups(){
            return [...this.groups].sort((a,b) => a.name.localeCompare(b.name))
        },
        headers(){
            return [
                {label: 'UID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Nom', name: 'name', type: TEXT_TYPE, sortable: true},
                {label: 'Description', name: 'description', type: TEXT_TYPE},
                {label: 'Membres', name: 'members', type: COMPONENT_TYPE, component: GroupEffectif},
                {label: 'Créateur', name: 'createBy', type: COMPONENT_TYPE, component: GroupCreateBy},
                {label: 'Date création', name: 'createdAt', type: DATE_TYPE, sortable: true},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
            ]
        }
    }
}
</script>

<style>

</style>